import React from 'react'
import MetaData from '../../components/MetaData'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { Title, Section, Box, Text } from '../../components/Core'
import Hero from '../../sections/common/Hero'
import { useTranslation } from 'react-i18next'
import PageWrapper from '../../components/PageWrapper'
import { useSelector } from 'react-redux'

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 2.25;
    a {
      color: ${({ theme }) => theme.colors.primary} !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.primaryLight} !important;
      }
    }
  }
`

const Contact1 = () => {
  const { t } = useTranslation()
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return (
    <>
      <MetaData page="default" />
      <PageWrapper headerDark footerDark>
        <Hero title={t('contactPage.getInTouch')}>
          {t('contactPage.supportTeam')}
        </Hero>
        <Section bg={userDarkMode ? 'black' : 'bg'}>
          <Container role="main" id="content">
            <Row>
              <Col xs="6" md="4" lg="3">
                <div className=" mb-5 mb-lg-4">
                  <Title color={userDarkMode ? 'light' : 'dark'} variant="card">
                    {t('contactPage.headquarters')}
                  </Title>
                  <UlStyled>
                    <li>
                      <Text
                        color={userDarkMode ? 'light' : 'dark'}
                        variant="small"
                      >
                        Coinigy Inc.
                        <br />
                        790 N Milwaukee St
                        <br />
                        Suite 300
                        <br />
                        Milwaukee, WI, 53202
                        <br />
                        United States
                      </Text>
                    </li>
                    <li>
                      <a href="tel:+14143012289">+1 (414) 301-2289</a>
                    </li>
                  </UlStyled>
                </div>
              </Col>
              <Col xs="6" md="4" lg="2">
                <div className=" mb-5 mb-lg-4">
                  <Title color={userDarkMode ? 'light' : 'dark'} variant="card">
                    {t('contactPage.social')}
                  </Title>
                  <UlStyled>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://facebook.com/coinigy"
                      >
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://www.instagram.com/coinigy"
                      >
                        Instagram
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://linkedin.com/company/coinigy-inc"
                      >
                        LinkedIn
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://t.me/coinigy"
                      >
                        Telegram
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://twitter.com/coinigy"
                      >
                        Twitter
                      </a>
                    </li>
                  </UlStyled>
                </div>
              </Col>
              <Col xs="6" md="4" lg="2">
                <div className=" mb-5 mb-lg-4">
                  <Title color={userDarkMode ? 'light' : 'dark'} variant="card">
                    {t('contactPage.streaming')}
                  </Title>
                  <UlStyled>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://www.bitchute.com/channel/itKxEPBWdT9g/"
                      >
                        BitChute
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://bittube.tv/profile/Coinigy"
                      >
                        BitTube
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://odysee.com/@coinigy:2"
                      >
                        Odysee
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://www.youtube.com/c/Coinigy/"
                      >
                        YouTube
                      </a>
                    </li>
                  </UlStyled>
                </div>
              </Col>
              <Col xs="6" md="4" lg="2">
                <div className="mb-5 mb-lg-4">
                  <Title color={userDarkMode ? 'light' : 'dark'} variant="card">
                    {t('contactPage.Blogs')}
                  </Title>
                  <UlStyled className="mb-5 mb-lg-6">
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="http://coinigy.medium.com/"
                      >
                        Medium
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://www.reddit.com/r/coinigy/"
                      >
                        Reddit
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://steemit.com/@coinigy.com"
                      >
                        Steemit
                      </a>
                    </li>
                  </UlStyled>
                  <Title color={userDarkMode ? 'light' : 'dark'} variant="card">
                    {t('contactPage.shop')}
                  </Title>
                  <UlStyled className="mb-5 mb-lg-6">
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://coinigy.myshopify.com/"
                      >
                        Bodega
                      </a>
                    </li>
                  </UlStyled>
                </div>
              </Col>
              <Col xs="12" md="6" lg="3">
                <Box
                  className="px-4 py-3"
                  border="1px solid"
                  borderRadius={10}
                  borderColor="border"
                  bg={userDarkMode ? 'dark' : 'white'}
                >
                  <Title color={userDarkMode ? 'light' : 'dark'} variant="card">
                    {t('contactPage.techSupport')}
                  </Title>
                  <UlStyled>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.coinigy.com/"
                      >
                        {t('contactPage.supportCenter')}
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.coinigy.com/hc/en-us/requests/new"
                      >
                        {t('contactPage.submitReq')}
                      </a>
                    </li>
                  </UlStyled>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default Contact1
